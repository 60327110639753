@import "../../const/settings.scss";

.wp-cta-action {
  .wp-cta {
    position: fixed;
    bottom: 40px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    gap: 10px;
    .cta-link {
      background: #29c0d3;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .animation {
      position: relative;
      .coccoc-alo-ph-circle {
        width: 100px;
        height: 100px;
        position: absolute;
        background-color: transparent;
        top: -17px;
        left: -17px;
        border-radius: 50%;
        border: 2px solid rgba(255, 137, 139, 0.5);
        animation: b 2.2s infinite ease-in-out;
        @keyframes b {
          0% {
            transform: rotate(0) scale(0.5) skew(1deg);
            opacity: 0.1;
          }
          30% {
            transform: rotate(0) scale(0.7) skew(1deg);
            opacity: 0.5;
          }
          100% {
            transform: rotate(0) scale(1) skew(1deg);
            opacity: 0.1;
          }
        }
      }
      .coccoc-alo-ph-circle-fill {
        width: 88px;
        height: 88px;
        top: -11px;
        left: -11px;
        border-radius: 50%;
        background-color: rgba(255, 137, 139, 0.5);
        opacity: 0.75 !important;
        position: absolute;
        animation: c 2.3s infinite ease-in-out;
        @keyframes c {
          0% {
            transform: rotate(0) scale(0.7) skew(1deg);
            opacity: 0.2;
          }

          50% {
            transform: rotate(0) scale(1) skew(1deg);
            opacity: 0.2;
          }
          100% {
            transform: rotate(0) scale(0.7) skew(1deg);
            opacity: 0.2;
          }
        }
      }
      .phone {
        display: block;
        animation: d 1s infinite ease-in-out;
        @keyframes d {
          10% {
            transform: rotate(-25deg) scale(1) skew(1deg);
          }

          20% {
            transform: rotate(25deg) scale(1) skew(1deg);
          }
          30% {
            transform: rotate(-25deg) scale(1) skew(1deg);
          }
          40% {
            transform: rotate(25deg) scale(1) skew(1deg);
          }
          50% {
            transform: rotate(0) scale(1) skew(1deg);
          }
          100% {
            transform: rotate(0) scale(1) skew(1deg);
          }
        }
      }

      @include media(md) {
        .coccoc-alo-ph-circle {
          width: 80px;
          height: 80px;
          top: -21px;
          left: -21px;
        }
        .coccoc-alo-ph-circle-fill {
          width: 60px;
          height: 60px;
        }
        .phone {
          img {
            width: 38px;
            height: 38px;
          }
        }
      }
    }

    @include media(md) {
      right: 22px;
      .cta-link {
        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }

  .wp-fab {
    bottom: 5px;
    position: fixed;
    right: 5px;
    z-index: 90;
    display: none;
    .fab {
      align-items: center;
      background: rgba(41, 192, 211, 1);
      border-radius: 50%;
      bottom: 47px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      display: flex;
      height: 60px;
      justify-content: center;
      margin: 0;
      overflow: hidden;
      position: absolute;
      right: 10px;
      transform: rotate(0deg);
      transition: all 0.15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
      width: 60px;
      z-index: 1000;
      .animation-btn {
        animation: d 1s infinite ease-in-out;

        @keyframes d {
          10% {
            transform: rotate(-25deg) scale(1) skew(1deg);
          }
          20% {
            transform: rotate(25deg) scale(1) skew(1deg);
          }
          30% {
            transform: rotate(-25deg) scale(1) skew(1deg);
          }
          40% {
            transform: rotate(25deg) scale(1) skew(1deg);
          }
          50% {
            transform: rotate(0) scale(1) skew(1deg);
          }
          100% {
            transform: rotate(0) scale(1) skew(1deg);
          }
        }
      }
    }

    .coccoc-alo-ph-circle {
      width: 100px;
      height: 100px;
      position: relative;
      background-color: transparent;
      border-radius: 50%;
      top: 60px;
      left: 10px;
      border: 2px solid rgba(41, 192, 211, 0.52);
      animation: b 2.2s infinite ease-in-out;

      @keyframes b {
        0% {
          transform: rotate(0) scale(0.5) skew(1deg);
          opacity: 0.1;
        }
        30% {
          transform: rotate(0) scale(0.7) skew(1deg);
          opacity: 0.5;
        }
        100% {
          transform: rotate(0) scale(1) skew(1deg);
          opacity: 0.1;
        }
      }
    }

    .coccoc-alo-ph-circle-fill {
      width: 88px;
      height: 88px;
      top: -33px;
      left: 16px;
      border-radius: 50%;
      background-color: rgba(41, 192, 211, 0.52);
      opacity: 0.75 !important;
      position: relative;
      animation: c 2.3s infinite ease-in-out;

      @keyframes c {
        0% {
          transform: rotate(0) scale(0.7) skew(1deg);
          opacity: 0.2;
        }
        50% {
          transform: rotate(0) scale(1) skew(1deg);
          opacity: 0.2;
        }
        100% {
          transform: rotate(0) scale(0.7) skew(1deg);
          opacity: 0.2;
        }
      }
    }
    .fab-checkbox {
      display: none !important;

      &:checked ~ .floating-overlay {
        background: rgba(0, 0, 0, 0.8);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1;
      }

      &:checked ~ .fab {
        transform: rotate(90deg);
        transition: all 0.15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
      }

      &:checked ~ .fab .icon-cps-fab-menu {
        background-position: -291px -70px;
        background-size: 615px;
        height: 30px;
        margin: 0;
        width: 30px;
      }

      &:checked ~ .fab-wheel {
        transform: scale(1);
      }

      &:checked ~ .fab-wheel .fab-title {
        opacity: 1;
      }

      &:not(:checked) ~ .fab {
        box-shadow: 0 0 0 0 rgba(41, 192, 211, 1);
        cursor: pointer;
      }
    }

    .fab-wheel {
      bottom: -68px;
      height: 220px;
      position: absolute;
      right: 15px;
      transform: scale(0);
      transform-origin: bottom right;
      transition: all 0.3s ease;
      width: 300px;
      z-index: 12;

      .fab-action {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        text-decoration: none;

        &-2 {
          right: 5px;
          top: -40px;

          .fab-button-2 {
            background: #d41515;
          }
        }

        &-3 {
          bottom: 155px;
          left: 38px;

          .fab-button-3 {
            background: linear-gradient(45deg, #0084ff, #9f2ea8);
          }
        }

        &-4 {
          bottom: 70px;
          left: 80px;

          .fab-button-4 {
            background: #2f82fc;
          }
        }
      }

      .fab-title {
        float: left;
        margin: 0 5px 0 0;
        opacity: 0;
      }

      .fab-button {
        align-items: center;
        background: #0f1941;
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        color: #fff;
        display: flex;
        float: left;
        font-size: 24px;
        height: 45px;
        justify-content: center;
        overflow: hidden;
        padding: 4px;
        transition: all 1s ease;
        width: 45px;
      }
    }

    [class*="icon-cps-"] {
      background-image: url("../../../public/icon/combination-cta.png") !important;
      background-repeat: no-repeat;
      background-size: 453px;
      display: inline-block;
      vertical-align: middle;
    }

    .icon-cps-fab-menu {
      background-position: -649px 0;
      background-size: 694px;
      height: 50px;
      margin: 0 !important;
      width: 50px;
    }

    .icon-cps-phone {
      background-position: -51px -49px;
      height: 28px;
      width: 28px;
    }

    .icon-cps-chat {
      background-position: -369px 0;
      height: 30px;
      width: 30px;
    }

    .icon-cps-chat-zalo {
      background-position: -362px -1px;
      background-size: 515px;
      height: 30px;
      width: 30px;
    }

    .suggestions-chat-box {
      align-items: center;
      background: #277cea;
      border: 1px solid #1d72e0;
      border-radius: 10px;
      bottom: 7%;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      color: #fff;
      display: flex;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      min-height: 50px;
      min-width: 140px;
      position: fixed;
      right: 80px;
      z-index: 11;

      #btnClose {
        left: 2px;
        position: absolute;
        top: 2px;
      }
    }

    i.icon-cps-face {
      background-position: -177px 0;
      height: 28px;
      width: 28px;
    }
  }
  .prioritize-wp-fab {
    z-index: 200;
  }

  @include media(md) {
    .wp-cta {
      display: none;
    }
    .wp-fab {
      display: block;
    }
  }
}

.wp_popup_qc {
  top: 0;
  .ant-modal-content {
    background: #fff0;
    box-shadow: unset;
  }
  .wp_popup {
    width: 100%;
    position: relative;
  }
  .wp_popup_cta {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    .popup_cta {
      cursor: pointer;
      &:hover {
        animation: none;
        scale: 1.1;
      }
      animation: scaleAnimation 1.5s ease-in-out infinite;
      @keyframes scaleAnimation {
        from {
          scale: 1.1;
        }
        50% {
          scale: 1;
        }
        to {
          scale: 1.1;
        }
      }
      width: 60%;
      height: auto;
    }
  }

  .popup_qc {
    object-fit: contain;
    // max-width: 861px;
    width: 100%;
    max-height: 550px;
    height: fit-content;
    cursor: pointer;
  }
  .icon_close {
    cursor: pointer;
    position: absolute;
    width: 62px;
    height: 62px;
    right: 20px;
    top: -33px;
    @include media(sm) {
      width: 35px;
      height: 35px;
    }
  }
  .ant-modal-close {
    top: 113px;
    right: 129px;
    .ant-modal-close-x {
      display: none;
    }
    @include media(sm) {
      top: 220px;
      right: 29px;
    }
  }
  .ant-modal-body {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    @include media(sm) {
      padding: 0;
    }
  }
}
.wp-notification {
  padding: 40px !important;
  .ant-modal-content {
    padding: 20px 24px !important;
  }
  .ant-modal {
    height: calc(100vh - 77%);
  }
  .wp-content {
    padding: 20px 12px !important;
    font-size: 20px;
    font-weight: bold;
  }
  .ant-modal-footer {
    text-align: center !important;
  }
  @include media(md) {
    padding: 14px !important;
    .wp-content {
      padding: 10px !important;
    }
  }
}
